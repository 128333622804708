import { Box as MuiBox, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { customBlue } from '../../theme/colors';
import { useTranslation } from '../../containers/TranslationProvider';

const Box = styled(MuiBox)<{ colorBorder: string }>`
  cursor: pointer;
  font-size: large;
  display: flex;
  gap: 1rem;
  align-items: center;
  border: 1px solid transparent;
  padding: 5px 10px;
  border-radius: 10px;
  transition: all 0.3s;
  &:hover {
    border: 1px solid ${(props) => props.colorBorder};
  }
`;
const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

export const LangDrop = () => {
  const [anchorMenu, setAnchorMenu] = useState<Element | null>(null);
  const { setLang, lang } = useTranslation();

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <React.Fragment>
      <Box
        aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={(e: any) => {
          e.stopPropagation();
          toggleMenu(e);
        }}
        colorBorder={customBlue['200']}
      >
        <Typography fontSize={15}> {lang.toUpperCase()}</Typography>

        <Flag src={`${lang}.png`} alt={'pl'} />
      </Box>

      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
        onClick={(e: any) => {
          e.stopPropagation();
          closeMenu();
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          value="pl"
          onClick={() => {
            setLang(() => 'pl');
          }}
        >
          PL
        </MenuItem>
        <MenuItem
          value="en"
          onClick={() => {
            setLang(() => 'en');
          }}
        >
          EN
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
