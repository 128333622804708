import { Box as MuiBox, Tooltip, styled } from '@mui/material';

const Box = styled(MuiBox)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const Logo = () => {
  return (
    <Box>
      <Tooltip
        title={<p style={{ color: '#fff', fontSize: '12px' }}>Home page</p>}
      >
        <a href="https://www.cblog.pl/" target="_blank">
          <img
            src={`fullLogo.png`}
            width={'80'}
            height={'80'}
            alt="CBLog"
            style={{
              objectFit: 'contain',
            }}
          />
        </a>
      </Tooltip>
    </Box>
  );
};
