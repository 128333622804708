import { FormProvider } from './FormProvider';
import { StatusListProvider } from './StatusListProvider';
import { TranslationProvider } from './TranslationProvider';

const providers = [StatusListProvider, TranslationProvider, FormProvider];

export const ProviderGroup = ({ children }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const ReducedProviders = providers.reduceRight((children, Provider) => {
    return <Provider>{children}</Provider>;
  }, children);
  return <>{ReducedProviders}</>;
};
