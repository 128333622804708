import {
  TextField as MuiTextField,
  Grid,
  styled,
  Box as MuiBox,
  Alert as MuiAlert,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import {
  labelText,
  notFoundMessage,
  notFoundMessageSingle,
  title,
} from '../../../translations/translation';
import { useTranslation } from '../../../containers/TranslationProvider';
import { spacing } from '@mui/system';
import { useStatusList } from '../../../containers/StatusListProvider';
import { device } from '../../../theme/breakpoints';

interface FormDishBodyProps {
  submitButton: JSX.Element;
  clearButton: JSX.Element;
  counter: number;
}

const Box = styled(MuiBox)`
  padding: 1rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

const BtnBox = styled(MuiBox)`
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  @media ${device.mobileL} {
    justify-content: center;
  }
`;

const TextField = styled(MuiTextField)`
  font-size: 50;
`;
const Alert = styled(MuiAlert)(spacing);

export const SearchFormBody: React.FC<FormDishBodyProps> = ({
  submitButton,
  clearButton,
  counter,
}) => {
  const { errors, handleBlur, handleChange, handleSubmit, touched, values } =
    useFormikContext<{ ids: string }>();
  const { lang } = useTranslation();
  const { errorMessage, setErrorMessage, notFound, setNotFoundParcel } =
    useStatusList();

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Box>
        {errorMessage && (
          <Alert
            severity="error"
            my={2}
            sx={{
              fontSize: '15px',
              display: 'flex',
              gap: '20px',
              alignItems: 'center',
            }}
          >
            {errorMessage}
          </Alert>
        )}
        {notFound.length ? (
          <Alert
            severity="info"
            my={2}
            sx={{
              fontSize: '15px',
              display: 'flex',
              gap: '20px',
              alignItems: 'center',
            }}
          >
            {notFound.length === 1
              ? `${notFoundMessageSingle[lang]} ${notFound.join(', ')}`
              : `${notFoundMessage[lang]}(${
                  notFound.length
                }/${counter}): ${notFound.join(', ')}`}
          </Alert>
        ) : null}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="ids"
              label={labelText[lang]}
              value={values.ids || ''}
              fullWidth
              helperText={touched.ids && errors.ids}
              error={Boolean(touched.ids && errors.ids)}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
                setErrorMessage('');
                setNotFoundParcel([]);
              }}
              multiline
              rows={8}
              required
              inputProps={{
                style: { fontSize: 15 },
              }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '2rem 0 0 0',
          }}
        >
          <BtnBox>
            {clearButton}
            {submitButton}
          </BtnBox>
        </div>
      </Box>
    </form>
  );
};
