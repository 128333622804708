import { Formik } from 'formik';
import * as Yup from 'yup';
import { PropsWithChildren } from 'react';

type SearchFormConfigProps = PropsWithChildren<{
  initialValues: {
    ids: string;
  };
}>;

export const SearchFormConfig = (props: SearchFormConfigProps) => {
  const { children, initialValues } = props;
  const validation = Yup.object().shape({
    ids: Yup.string().trim().required(''),
  });

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      validationSchema={validation}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
      }}
    >
      {children}
    </Formik>
  );
};
