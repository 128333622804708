import { Route, Routes } from 'react-router-dom';
import { Footer } from './components/footer/Footer';
import { Header } from './components/header/Header';
import { Main } from './components/main/Main';
import { Box as MuiBox, ThemeProvider, styled } from '@mui/material';
import typography from './theme/typography';

const Box = styled(MuiBox)`
  display: flex;
  flex-direction: column;
  flex: 0 4 0;
`;

function App() {
  return (
    <ThemeProvider theme={typography}>
      <Box>
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/:ids" element={<Main />} />
        </Routes>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
