import { createContainer } from 'unstated-next';
import { StatusInterface } from '../types/types';
import { useState } from 'react';

const StatusListContainer = () => {
  const [statusList, setStatusList] = useState<StatusInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [notFound, setNotFoundParcel] = useState<string[]>([]);
  return {
    statusList,
    setStatusList,
    isLoading,
    setIsLoading,
    errorMessage,
    setErrorMessage,
    notFound,
    setNotFoundParcel,
  };
};

const useStatusListContainer = createContainer(StatusListContainer);
export const useStatusList = useStatusListContainer.useContainer;
export const StatusListProvider = useStatusListContainer.Provider;
