import { createContainer } from 'unstated-next';
import { useState } from 'react';

const FormContainer = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return {
    isOpen,
    setIsOpen,
  };
};

const useFormContainer = createContainer(FormContainer);
export const useForm = useFormContainer.useContainer;
export const FormProvider = useFormContainer.Provider;
