import { useSearchParams } from 'react-router-dom';
import { SearchFormConfig } from './SearchFormConfig';
import { SearchFormLogic } from './SearchFormLogic';
import { useStatusList } from '../../../containers/StatusListProvider';
import { trackingApi } from '../../../api/trackingAPI';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import {
  Box as MuiBox,
  styled,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Typography,
} from '@mui/material';
import { useForm } from '../../../containers/FormProvider';
import { customBlue } from '../../../theme/colors';
import { useTranslation } from '../../../containers/TranslationProvider';
import { title } from '../../../translations/translation';

const AccordionSummary = styled(MuiAccordionSummary)``;

const AccordionDetails = styled(MuiAccordionDetails)``;
const Box = styled(MuiBox)`
  padding: 1rem;
`;

export const SearchForm = () => {
  const [searchParams] = useSearchParams();
  const { isOpen, setIsOpen } = useForm();
  const { setStatusList, setIsLoading, setErrorMessage, setNotFoundParcel } =
    useStatusList();
  const { lang } = useTranslation();
  const params = searchParams.get('parcelNumbers');
  const initialValues = {
    ids: params ? params : '',
  };
  useEffect(() => {
    if (params) {
      setIsLoading(true);
      trackingApi(params)
        .then((res) => {
          setStatusList(() => res.data);
          setNotFoundParcel(() => res.notFound);
          if (res.notFound.length) {
            setIsOpen(true);
          } else {
            setIsOpen(false);
          }
        })
        .catch((err) => {
          setStatusList(() => []);
          setErrorMessage(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    params,
    setStatusList,
    setIsLoading,
    setErrorMessage,
    setNotFoundParcel,
    setIsOpen,
  ]);

  return (
    <>
      <Box>
        <Accordion expanded={isOpen}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            disabled={false}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
          >
            <Typography
              variant="h2"
              gutterBottom
              display="inline"
              fontWeight={600}
              color={customBlue['1100']}
              style={{ margin: '0' }}
            >
              {`${title[lang].toUpperCase()}`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SearchFormConfig initialValues={initialValues}>
              <SearchFormLogic />
            </SearchFormConfig>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
