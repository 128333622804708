import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
import { X } from 'react-feather';
interface ClearBtnProps {
  clearFn: () => void;
  text: string;
  disabled: boolean;
}

const SubmitBtnStyle = styled(Button)`
  max-width: max-content;
  display: flex;
  gap: 1rem;
`;

const IconX = styled(X)<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? '#8f8f8f' : '#000')};
`;

export const ClearBtn: React.FC<ClearBtnProps> = ({
  clearFn,
  text,
  disabled,
}) => {
  return (
    <SubmitBtnStyle
      fullWidth
      variant="text"
      color="primary"
      onClick={clearFn}
      disabled={disabled}
    >
      <IconX disabled />
      <Typography fontSize={10} letterSpacing={2} color={'#8f8f8f'}>
        {text.toUpperCase()}
      </Typography>
    </SubmitBtnStyle>
  );
};
