export const btnSerachText = {
  en: 'Search',
  pl: 'Szukaj',
};

export const labelText = {
  en: 'Orders number (max. 40)',
  pl: ' Numery przesyłek (max. 40)',
};

export const notFoundMessage = {
  en: 'No parcel was found for given numbers ',
  pl: 'Nie znaleziono przesyłek o numerach ',
};
export const notFoundMessageSingle = {
  en: 'No parcel was found for given number: ',
  pl: 'Nie znaleziono paczki o numerze: ',
};

export const btnClearText = {
  en: 'Clear',
  pl: 'Wyczyść',
};

export const noStatus = {
  en: 'No status',
  pl: 'Brak status',
};

export const title = {
  en: 'Track and Trace',
  pl: 'Track and Trace',
};

export const allRights = {
  en: 'All rights reserved.',
  pl: 'Wszelkie prawa zastrzeżone.',
};

export const constcopyToClipboard = {
  en: 'Copy to clipboard.',
  pl: 'Skopiuj do schowka.',
};

export const successCopy = {
  en: 'Success.',
  pl: 'Sukces.',
};
