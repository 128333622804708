import { createContainer } from 'unstated-next';
import { StatusInterface } from '../types/types';
import { useState } from 'react';

const TranslationContainer = () => {
  const [lang, setLang] = useState<'pl' | 'en'>('pl');
  return { lang, setLang };
};

const useTranslationProvider = createContainer(TranslationContainer);
export const useTranslation = useTranslationProvider.useContainer;
export const TranslationProvider = useTranslationProvider.Provider;
