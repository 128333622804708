import { Link, Box as MuiBox, Typography } from '@mui/material';
import { customBlue } from '../../theme/colors';
import { getCurrentYear } from '../../utils/getCurrentYear';
import { Mail } from 'react-feather';
import styled from '@emotion/styled';
import { device } from '../../theme/breakpoints';
import { allRights } from '../../translations/translation';
import { useTranslation } from '../../containers/TranslationProvider';

const Box = styled(MuiBox)<{ colorBg: string; colorFont: string }>`
  background-color: ${(props) => props.colorBg};
  min-height: 5vh;
  width: 100%;
  /* position: fixed;
  bottom: 0; */
  color: ${(props) => props.colorFont};
  font-size: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${device.mobileM} {
    flex-direction: column;
  }
`;
const Item = styled.p`
  padding: 10px;
  @media ${device.mobileM} {
    padding: 0;
  }
`;
const ContactContainer = styled(MuiBox)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Icon = styled(Mail)`
  padding: 10px;
`;

export const Footer = () => {
  const { lang } = useTranslation();
  return (
    <Box colorBg={customBlue['1100']} colorFont={customBlue['100']}>
      <ContactContainer>
        <Icon />
        <Item>
          <Link
            component="button"
            variant="body1"
            underline="none"
            color={customBlue['100']}
            onClick={() => {
              window.location.href = 'nfo@cblog.pl';
            }}
          >
            <Typography fontSize={15}> info@cblog.pl</Typography>
          </Link>
        </Item>
      </ContactContainer>
      <Item>
        <Typography fontSize={15}>
          CBlog &#169; {getCurrentYear()} {allRights[lang]}
        </Typography>
      </Item>
    </Box>
  );
};
