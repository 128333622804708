import { useFormikContext } from 'formik';
import { SubmitBtn } from '../../ui/SubmitBtn';
import { SearchFormBody } from './SearchFormBody';
import { useStatusList } from '../../../containers/StatusListProvider';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from '../../../containers/TranslationProvider';
import { btnClearText, btnSerachText } from '../../../translations/translation';
import { ClearBtn } from '../../ui/ClearBtn';
export const SearchFormLogic = () => {
  const { setStatusList, isLoading, setErrorMessage, setNotFoundParcel } =
    useStatusList();
  const { lang } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [counter, setCounter] = useState<number>(0);
  const { values, isValid, resetForm, dirty, setFieldValue, initialValues } =
    useFormikContext<{ ids: string }>();

  useEffect(() => {
    if (!values.ids.length) {
      setSearchParams();
      setStatusList([]);
      setCounter(0);
    } else {
      console.log(values.ids.split('\n'));
      setCounter(values.ids.split('\n').length);
    }
  }, [values, setSearchParams, setStatusList]);

  const submitHandler = async () => {
    setSearchParams({ parcelNumbers: values.ids });
  };
  const clearHandler = () => {
    setFieldValue('ids', '');
    resetForm();
    setErrorMessage('');
    setNotFoundParcel([]);
    initialValues.ids = '';
    setSearchParams();
  };
  return (
    <>
      <SearchFormBody
        submitButton={
          <SubmitBtn
            disabled={false}
            submitFn={submitHandler}
            isLoading={isLoading}
            text={btnSerachText[lang]}
            counter={counter}
          />
        }
        clearButton={
          <ClearBtn
            text={btnClearText[lang]}
            clearFn={clearHandler}
            disabled={!values.ids.length}
          />
        }
        counter={counter}
      />
    </>
  );
};
