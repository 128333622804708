export const size = {
  mobileS: '320px',
  mobileM: '420px',
  mobileL: '550px',
  tablet: '768px',
  tabletX: '905px',
  laptop: '1100px',
  laptopM: '1300px',
  laptopL: '1440px',
  laptopX: '1600px',
  laptopXL: '1710px',
  laptopXXL: '1840px',
} as const;

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  tabletX: `(max-width: ${size.tabletX})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopM: `(max-width: ${size.laptopM})`,
  laptopL: `(max-width: ${size.laptopL})`,
  laptopX: `(max-width: ${size.laptopX})`,
  laptopXL: `(max-width: ${size.laptopXL})`,
  laptopXXL: `(max-width: ${size.laptopXXL})`,
} as const;
