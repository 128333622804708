import axios from 'axios';
import { apiConfig } from '../vendor/config';
export const trackingApi = async (parcelsNumber: string) => {
  // parcelsNumber = parcelsNumber.split(' ').join('');
  // const arr = parcelsNumber.split(',');
  // arr.forEach((el) => el.trim());
  try {
    const res = await axios.get(`${apiConfig.apiUrl}/tracking/`, {
      params: {
        parcelNumbers: parcelsNumber,
      },
    });
    return res.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    throw new Error(
      err.response.data.error ? err.response.data.error : 'Unhandled error'
    );
  }
};
