import { format } from 'date-fns';

export const changeFormatDate = (date: string | null) => {
  if (!date) {
    return '';
  }
  const newDate = new Date(date);
  const fullDateTimeUpdate = format(newDate, 'yyyy-MM-dd HH:mm:ss');
  return fullDateTimeUpdate;
};
