import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StatusHistoryInterface, StatusInterface } from '../../../types/types';
import {
  Box as MuiBox,
  styled,
  Divider as MuiDivider,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Tooltip,
} from '@mui/material';
import { useTranslation } from '../../../containers/TranslationProvider';
import { changeFormatDate } from '../../../utils/changeFormatDate';
import { minWidth, spacing } from '@mui/system';
import { Box as BoxIcon, Hexagon, Copy } from 'react-feather';
import { customBlue } from '../../../theme/colors';
import { device } from '../../../theme/breakpoints';
import {
  constcopyToClipboard,
  noStatus,
  successCopy,
} from '../../../translations/translation';
import DOMPurify from 'dompurify';
import copy from 'copy-to-clipboard';
import { useState } from 'react';

const Box = styled(MuiBox)`
  padding: 0.5rem 7rem;
  @media ${device.laptopL} {
    padding: 0.5rem 1rem;
  }
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  height: 40px;
  @media ${device.mobileM} {
    height: 60px;
  }
  @media ${device.mobileS} {
    height: 80px;
  }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 1rem 3rem;
  @media ${device.laptop} {
    padding: 1rem 1rem;
  }
`;

const CurrentStatusContainer = styled(MuiBox)`
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 100%;
  height: 100%;
  /* justify-content: space-between; */

  @media ${device.mobileL} {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  @media ${device.mobileM} {
    flex-direction: column;
    align-items: center;
  }
`;
const StatsuColor = styled(MuiBox)<{ colorBg: string }>`
  /* padding: 0.8rem 2rem; */
  background-color: ${(props) => props.colorBg};
  color: #fff;
  border-radius: 5px;
  width: 25rem;
  display: flex;
  justify-content: center;
  @media ${device.laptopM} {
    width: 15rem;
  }
`;

const StatsuColorRow = styled(MuiBox)<{ colorBg: string }>`
  background-color: ${(props) => props.colorBg};
  border-radius: 5px;
  width: 20rem;
`;

const DetailsRow = styled(MuiBox)`
  display: flex;
  align-items: center;
`;
const DetilsText = styled(MuiBox)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1rem;
  margin-right: 1rem;
  gap: 2rem;
  @media ${device.laptop} {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1rem;
  }
`;
const FirstPart = styled(MuiBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`;
const SmallContainer = styled(MuiBox)`
  display: flex;
  align-items: center;
  min-width: 35rem;
  justify-content: space-between;
  gap: 1rem;
  @media ${device.laptopM} {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
  }
`;
const SmallContainerTwo = styled(MuiBox)`
  display: flex;
  align-items: center;
  min-width: 25rem;
  justify-content: space-between;
  gap: 1rem;
  @media ${device.laptopM} {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
  }
`;
const DescText = styled(Typography)`
  max-width: 1000px;
  min-width: 1000px;
  @media ${device.laptopXXL} {
    max-width: 900px;
    min-width: 900px;
  }
  @media ${device.laptopXL} {
    max-width: 800px;
    min-width: 800px;
  }
  @media ${device.laptopX} {
    max-width: 650px;
    min-width: 650px;
  }
  @media ${device.laptopL} {
    max-width: 600px;
    min-width: 600px;
  }
  @media ${device.laptopM} {
    max-width: 850px;
    min-width: 850px;
  }
  @media ${device.laptop} {
    max-width: 100%;
    min-width: 100%;
  }
`;
const Divider = styled(MuiDivider)(spacing);

export const Item = (props: {
  data: StatusInterface;
  defaultExpanded: boolean;
}) => {
  const { data, defaultExpanded } = props;
  const { lang } = useTranslation();
  const [isCopy, setIsCopy] = useState<boolean>(false);

  const copyToClipboard = (val: string) => {
    copy(val);
    setIsCopy(true);
  };
  return (
    <Box>
      <Accordion
        defaultExpanded={Boolean(defaultExpanded && data.currentStatus)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          disabled={!Boolean(data.currentStatus)}
          style={{ minHeight: '25px', marginTop: '0px', height: '25px' }}
        >
          <CurrentStatusContainer>
            {data.currentStatus ? (
              <StatsuColor colorBg={data.currentStatus.statusDetails.color}>
                <Typography fontSize={13} fontWeight={400} letterSpacing={2}>
                  {data.currentStatus?.statusDetails[lang]}
                </Typography>
              </StatsuColor>
            ) : (
              <StatsuColor colorBg={'#707070'}>
                <Typography fontSize={13} fontWeight={400} letterSpacing={2}>
                  {`${noStatus[lang]}`}
                </Typography>
              </StatsuColor>
            )}
            <Typography fontSize={14} letterSpacing={1}>
              {data.packageId}
            </Typography>
          </CurrentStatusContainer>
          <Tooltip
            title={
              isCopy ? (
                <Typography fontSize={10}>{`${successCopy[lang]}`}</Typography>
              ) : (
                <Typography fontSize={10}>
                  {`${constcopyToClipboard[lang]}`}
                </Typography>
              )
            }
            style={{
              whiteSpace: 'nowrap',
              cursor: 'pointer',
            }}
          >
            <Copy
              onClick={(e) => {
                e.stopPropagation();
                copyToClipboard(data.packageId);
                setTimeout(() => {
                  setIsCopy(false);
                }, 1000);
              }}
              color={customBlue['500']}
              width={21}
              height={21}
              style={{ marginRight: '10px' }}
            />
          </Tooltip>
        </AccordionSummary>
        <AccordionDetails>
          <Divider my={0.2} />
          {data.statusHistory.map(
            (el: StatusHistoryInterface, index: number) => {
              const sanitizedDataDescription = () => ({
                __html: DOMPurify.sanitize(el.description),
              });
              return (
                <>
                  <DetailsRow>
                    {!index ? (
                      <BoxIcon
                        color={customBlue['1100']}
                        fill={customBlue['100']}
                        width={18}
                        height={18}
                      />
                    ) : (
                      <Hexagon
                        fill={customBlue['100']}
                        color={customBlue['200']}
                        width={18}
                        height={18}
                      />
                    )}
                    <DetilsText>
                      <FirstPart>
                        <SmallContainer>
                          <StatsuColorRow colorBg={'#fff'}>
                            <Typography
                              fontSize={12}
                              fontWeight={600}
                              letterSpacing={2}
                            >
                              {el.statusDetails[lang]}
                            </Typography>
                          </StatsuColorRow>
                          <Box
                            style={{
                              minWidth: '10rem',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              padding: '0',
                            }}
                          >
                            <SmallContainerTwo>
                              <Typography
                                fontSize={12}
                                letterSpacing={2}
                                sx={{ maxWidth: '200px', minWidth: '200px' }}
                              >
                                {el.terminal ? `${el.terminal}` : '---'}
                              </Typography>
                              <DescText
                                fontSize={12}
                                letterSpacing={2}
                                dangerouslySetInnerHTML={sanitizedDataDescription()}
                              ></DescText>
                            </SmallContainerTwo>
                          </Box>
                        </SmallContainer>
                      </FirstPart>
                      <Typography
                        fontSize={10}
                        letterSpacing={2}
                        sx={{ maxWidth: '150px', minWidth: '150px' }}
                      >
                        {changeFormatDate(el.timestamp)}
                      </Typography>
                    </DetilsText>
                  </DetailsRow>
                  {index !== data.statusHistory.length - 1 && (
                    <Divider my={0.2} />
                  )}
                </>
              );
            }
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
