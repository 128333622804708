import { Box as MuiBox } from '@mui/material';
import { SearchForm } from './form/SearchForm';
import { ParcelList } from './list/ParcelList';
import { useStatusList } from '../../containers/StatusListProvider';
import { customBlue } from '../../theme/colors';
import styled from 'styled-components';
import { device } from '../../theme/breakpoints';

const Box = styled(MuiBox)`
  width: 100%;
  margin: auto;
  height: 100%;
`;

const Container = styled(MuiBox)<{ colorBg: string }>`
  width: 80%;
  margin: auto;
  @media ${device.tablet} {
    width: 95%;
  }
  @media ${device.mobileM} {
    width: 100%;
  }
`;

const ContainerParcelList = styled(MuiBox)<{ colorBg: string }>`
  width: 100%;
  margin: auto;
  background-color: ${(props) => props.colorBg};
  min-height: 85vh;
`;

const ImgContainer = styled(MuiBox)`
  width: max-content;
  margin: 0 auto;
`;

export const Main = () => {
  const { statusList } = useStatusList();
  return (
    <Box>
      <Container colorBg={customBlue['1000']}>
        <SearchForm />
      </Container>
      <ContainerParcelList colorBg={customBlue['1000']}>
        <ParcelList data={statusList} />

        <ImgContainer>
          <img
            src={`logotype.png`}
            width={'330'}
            height={'330'}
            alt="CBLog"
            style={{
              objectFit: 'contain',
              filter: `brightness(0) invert(1)`,
            }}
          />
        </ImgContainer>
      </ContainerParcelList>
    </Box>
  );
};
