import styled from 'styled-components';
import { Loader } from './Loder';
import { Button, Typography } from '@mui/material';
import { customBlue } from '../../theme/colors';
import { Search } from 'react-feather';
interface SubmitBtnProps {
  disabled: boolean;
  submitFn: () => Promise<void>;
  isLoading: boolean;
  text: string;
  counter: number;
}

const SubmitBtnStyle = styled(Button)<{
  disabled: boolean;
  colorDisabledBtn: string;
  colorNotDisabledBtn: string;
}>`
  max-width: max-content;
  display: flex;
  gap: 1rem;
  font-size: 20px;
  background-color: ${({ disabled, colorDisabledBtn, colorNotDisabledBtn }) =>
    disabled ? colorDisabledBtn : colorNotDisabledBtn}!important;
`;

export const SubmitBtn: React.FC<SubmitBtnProps> = ({
  disabled,
  submitFn,
  isLoading,
  text,
  counter,
}) => {
  return (
    <SubmitBtnStyle
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      onClick={submitFn}
      disabled={disabled}
      colorDisabledBtn={customBlue['100']}
      colorNotDisabledBtn={customBlue['1100']}
    >
      {isLoading ? <Loader size="2rem" /> : <Search />}
      <Typography fontSize={12} letterSpacing={2}>
        {text.toUpperCase()} {Boolean(counter) && `(${counter})`}
      </Typography>
    </SubmitBtnStyle>
  );
};
