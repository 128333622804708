import ReactDOM from 'react-dom/client';
import './assets/index.css';
import App from './App';
import { ProviderGroup } from './containers/grupProvider';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <ProviderGroup>
      <App />
    </ProviderGroup>
  </BrowserRouter>
);
