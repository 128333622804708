import {
  Toolbar as MuiToolbar,
  styled,
  AppBar as MuiAppBar,
} from '@mui/material';
import { customBlue } from '../../theme/colors';
import { Logo } from './Logo';
import { LangDrop } from './LangDrop';

const AppBar = styled(MuiAppBar)<{ colorBg: string }>`
  background-color: ${(props) => props.colorBg};
`;

const Toolbar = styled(MuiToolbar)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: 0px 8px 17px 0px rgba(119, 122, 136, 0.57);
  -moz-box-shadow: 0px 8px 17px 0px rgba(119, 122, 136, 0.57);
  box-shadow: 0px 8px 17px 0px rgba(119, 122, 136, 0.57);
`;

export const Header = () => {
  return (
    <AppBar position="sticky" elevation={0} colorBg={customBlue['1100']}>
      <Toolbar>
        <Logo />
        <LangDrop />
      </Toolbar>
    </AppBar>
  );
};
