import { CircularProgress } from '@mui/material';
import styled from 'styled-components';
import { customBlue } from '../../theme/colors';

interface LoaderProps {
  size: string;
}
const WrapperLoder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loader = (props: LoaderProps) => {
  const { size } = props;
  return (
    <WrapperLoder>
      <CircularProgress
        style={{
          display: 'flex',
          width: size,
          height: size,
          color: `${customBlue[100]}`,
        }}
      />
    </WrapperLoder>
  );
};
