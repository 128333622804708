import { Box as MuiBox, styled } from '@mui/material';
import { Item } from './Item';
import { StatusInterface } from '../../../types/types';

export const Box = styled(MuiBox)`
  width: 100%;
  padding: 2rem 0;
`;

export const ParcelList: React.FC<{ data: StatusInterface[] }> = ({ data }) => {
  return (
    <Box>
      {data.map((el: StatusInterface, index: number) => {
        return <Item data={el} defaultExpanded={!index} />;
      })}
    </Box>
  );
};
